<template>
  <container-form :showLeftIcon="false">
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">O que você precisa ter em mãos para continuar:</h3>
        <b-button variant="primary" class="ml-2" @click="$emit('next-step')"> Continuar </b-button>
      </div>
    </template>

    <template #form>
      <div class="d-flex justify-content-start align-items-start">
        <div class="p-1 border-warning bg-light-warning rounded-lg mr-2">
          <feather-icon icon="TrendingUpIcon" size="24" class="text-dark" />
        </div>
        <div class="d-flex flex-column">
          <h4 class="mb-50">
            1. Faturamento, relação de frota, os últimos três balanços e balancete.
          </h4>
          <h5 class="mb-0 mt-50 text-muted">
            Separe quatro arquivos, nomeados conforme cada conteúdo.
          </h5>
        </div>
      </div>
      <div class="d-flex justify-content-start align-items-start mt-2">
        <div class="p-1 border-warning bg-light-warning rounded-lg mr-2">
          <feather-icon icon="CheckSquareIcon" size="24" class="text-dark" />
        </div>
        <div class="d-flex flex-column">
          <h4 class="mb-50">2. Permissão de acesso ao seu SCR</h4>
          <h5 class="mb-0 mt-50 text-muted">
            Responda um breve questionário para conceder o acesso.
          </h5>
        </div>
      </div>
      <b-alert variant="light" show class="mt-2 mb-0 p-2 border-light">
        <div class="d-flex justify-content-between align-items-center">
          <feather-icon icon="AlertTriangleIcon" size="38" class="mr-1" />
          <p>
            Fique atento para não deixar de fora nenhum dos documentos requisitados para a
            realização da sua análise de crédito. Isso é essencial para garantir uma análise rápida
            e obter resultados mais favoráveis.
          </p>
        </div>
      </b-alert>
    </template>
  </container-form>
</template>

<script>
import { BCard, BAlert, BButton } from 'bootstrap-vue'
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'

export default {
  name: 'NeedHelp',
  components: {
    BCard,
    BAlert,
    BButton,
    ContainerForm,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss"></style>
