<template>
  <b-card class="padlocks-bg need-help mb-0 h-100">
    <div class="d-flex flex-column justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <b-img :src="padlock" width="125" height="156" />
      </div>
      <div class="d-flex flex-row align-items-center justify-content-between p-0 w-100">
        <b-img :src="whats" fluid />
        <div>
          <p class="mb-0 need-help-text">Precisa de ajuda?</p>
          <small class="need-help-whats">Chame no WhatsApp</small>
        </div>
        <b-button
          class="btn btn-sm"
          target="_blank"
          href="https://wa.me/551130343417"
          variant="outline-primary"
        >
          Chamar
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg, BButton } from 'bootstrap-vue'
export default {
  name: 'NeedHelp',
  components: {
    BCard,
    BImg,
    BButton,
  },
  data() {
    return {
      padlock: require('@/assets/images/dashboard/padlocks.svg'),
      whats: require('@/assets/images/dashboard/whatsup.svg'),
    }
  },
}
</script>

<style lang="scss">
.need-help {
  max-width: 300px;
  min-width: 300px;
}

.need-help-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #242a32;
}

.need-help-whats {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #66788b;
}
</style>
