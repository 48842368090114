<template>
  <div>
    <credit-analysis-description class="w-100" v-if="step === 1" @next-step="step++" />
    <credit-analysis-documents class="w-100" v-if="step === 2" @next-step="step++" />
    <scr-permission v-if="step === 3" />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BImg, BButton, BBadge } from 'bootstrap-vue'
import NeedHelp from '@/views/client/dashboard/components/NeedHelp.vue'
import CreditAnalysisDescription from '../components/CreditAnalysisDescription.vue'
import CreditAnalysisDocuments from '../components/CreditAnalysisDocuments.vue'
import ScrPermission from '@/views/client/dashboard/components/manual_offer/ScrPermission.vue'
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'

export default {
  name: 'CreditAnalysis',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    NeedHelp,
    ScrPermission,
    ContainerForm,
    CreditAnalysisDocuments,
    CreditAnalysisDescription,
  },
  data() {
    return {
      step: 1,
    }
  },
}
</script>
