<template>
  <container-form :showLeftIcon="false" :cardBody="false">
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start align-items-start">
          <div class="p-1 border-warning bg-light-warning rounded-lg mr-2">
            <feather-icon icon="CheckSquareIcon" size="24" class="text-dark" />
          </div>
          <div class="d-flex flex-column">
            <h4 class="mb-50">1. Envio de documentos</h4>
            <h5 class="mb-0 mt-50 text-muted">
              Inclua abaixo os arquivos requisitados em cada sessão
            </h5>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <b-button
            variant="primary"
            class="ml-2"
            @click="$emit('next-step')"
            :disabled="!canGoToNextStep"
          >
            Continuar
          </b-button>
        </div>
      </div>
    </template>

    <template #form>
      <input type="file" ref="inputFile" style="display: none" @change="handleFileUpload" />
      <div class="border-light rounded-lg overflow-hidden d-flex flex-column">
        <div class="w-100 d-flex flex-row table-responsive h-100">
          <div
            class="border-light w-100 p-2 d-flex flex-column justify-content-between"
            v-for="(tipo, index) in documentTypes"
            :key="index"
          >
            <div>
              <h5 class="font-weight-bolder mb-1">{{ tipo.title }}</h5>
              <b-button size="sm" variant="primary mb-1 w-100" @click="chooseFile(tipo.key)">
                Buscar arquivo
              </b-button>
              <div class="docs">
                <div class="d-flex mb-5" v-if="!docs[tipo.key]">
                  <feather-icon icon="AlertCircleIcon" size="22" class="text-warning mr-1" />
                  <span>Nenhum incluído</span>
                </div>
                <div v-else>
                  <div
                    v-for="file in docs[tipo.key]"
                    :key="file.id"
                    class="d-flex flex-row justify-content-start align-items-center mb-75"
                  >
                    <b-badge
                      variant="light-primary"
                      class="mb-0 p-50"
                      v-b-tooltip.hover
                      :title="file.nome"
                    >
                      <feather-icon icon="PaperclipIcon" size="18" />
                      {{ truncateWithEllipsis(file.nome) }}
                    </b-badge>
                    <feather-icon
                      class="ml-1 mb-0 text-primary"
                      icon="Trash2Icon"
                      size="18"
                      @click="deleteFile(file)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <b-alert show class="p-1 rounded-lg mb-0 mt-1 border-warning bg-light-warning">
              <span class="text-dark" v-html="tipo.alert"></span>
            </b-alert>
          </div>
        </div>
        <div class="mb-0 px-2 py-1 gray-warning d-flex justify-content-between align-items-center">
          <feather-icon icon="AlertTriangleIcon" size="38" class="mr-1" />
          <p class="mb-0">
            Fique atento para não deixar de fora nenhum dos documentos requisitados para a
            realização da sua análise de crédito. Isso é essencial para garantir uma análise rápida
            e obter resultados mais favoráveis.
          </p>
        </div>
      </div>
    </template>
  </container-form>
</template>

<script>
import { BCard, BAlert, BButton, BBadge, VBTooltip } from 'bootstrap-vue'
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'
import { truncateWithEllipsis } from '@core/comp-functions/data_visualization/string'

export default {
  name: 'CreditAnalysisDocuments',
  components: {
    BCard,
    BAlert,
    BBadge,
    BButton,
    ContainerForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      docs: [],
      selectedFileType: null,
      canGoToNextStep: false,
      documentTypes: [
        {
          title: 'Faturamento',
          key: 'faturamento',
          alert: 'Inclua acima o faturamento dos <strong>últimos doze meses</strong>.',
        },
        {
          title: 'Três últimos balanços',
          key: 'balanco',
          alert: 'Neste bloco inclua o balanço dos <strong>três últimos anos</strong> .',
        },
        {
          title: 'Balancete',
          key: 'balancete',
          alert: 'Inclua acima os <strong>três últimos balancetes</strong> da sua empresa.',
        },
        {
          title: 'Relação de frota',
          key: 'lista_de_frota',
          alert: 'Inclua na relação <strong>placa, renavam, tipo e modelo dos veículos</strong>.',
        },
      ],
    }
  },
  async mounted() {
    await this.getDocs()
  },
  methods: {
    truncateWithEllipsis,
    async getDocs() {
      const { data } = await this.$store.dispatch('auth/getUserData')
      this.canGoToNextStep = !!data.analise_credito_documentos.length
      this.docs = data.analise_credito_documentos.reduce((result, current) => {
        ;(result[current['tipo_documento']] = result[current['tipo_documento']] || []).push(current)
        return result
      }, {})
    },
    chooseFile(type) {
      this.selectedFileType = type
      this.$refs.inputFile.click()
    },
    async handleFileUpload() {
      let selectedFile = this.$refs.inputFile.files[0]
      if (selectedFile) {
        const payload = { arquivo: selectedFile, tipo_documento: this.selectedFileType }

        this.$swal.fire({
          title: 'Fazendo upload do arquivo',
          text: 'Aguarde um instante...',
          showConfirmButton: false,
          allowOutsideClick: false,
        })

        try {
          await this.$store.dispatch('company/sendDocumentToAnalysis', payload)
          this.$swal.close()
        } catch (error) {
          console.log(error)
        } finally {
          this.$refs.inputFile.value = null
          this.selectedFileType = null
          await this.getDocs()
        }
      }
    },
    async deleteFile(file) {
      this.$swal
        .fire({
          title: 'Deseja continuar?',
          text: `Você está prestes a deletar o arquivo ${file.nome}.`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, deletar!',
        })
        .then(async (result) => {
          if (result.value) {
            this.$swal.showLoading()
            await this.$store.dispatch('company/deleteDocumentToAnalysis', file.id)
            this.$emit('deleted')
            this.$swal.fire('Pronto!', 'Arquivo deletado com sucesso.', 'success')
            await this.getDocs()
          }
        })
    },
  },
}
</script>

<style lang="scss">
.table-responsive {
  margin-bottom: 0px;
}

.gray-warning {
  background: #f8fafc;
}
</style>
